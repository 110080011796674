export default [
  {
    path: '/login-next',
    name: 'auth-login-next',
    component: () => import('@/views/pages/authentication/LoginSaemless.vue'),
    meta: {
      layout: 'single',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/tanghuay-home',
    name: 'tanghuay-home',
    component: () => import('@/views/Partner/tanghuay-online/home.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-deposit',
    name: 'tanghuay-deposit',
    component: () => import('@/views/Partner/tanghuay-online/deposit.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-withdraw',
    name: 'tanghuay-withdraw',
    component: () => import('@/views/Partner/tanghuay-online/withdraw.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/:LottoName/:LottoHead/:LottoSubHead/:CloseDate/:round_id',
    name: 'tanghuay-online',
    component: () => import('@/views/Partner/tanghuay-online/tanghuay.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online-v2/:LottoName/:LottoHead/:LottoSubHead/:CloseDate/:round_id',
    name: 'tanghuay-online-v2',
    component: () => import('@/views/Partner/tanghuay-online/tanghuay_v2.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-round',
    name: 'tanghuay-round',
    component: () => import('@/views/Partner/tanghuay-online/round.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/result',
    name: 'tanghuay-result',
    component: () => import('@/views/Partner/tanghuay-online/result.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/winlose',
    name: 'tanghuay-winlose',
    component: () => import('@/views/Partner/tanghuay-online/winlose.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/list',
    name: 'tanghuay-list',
    component: () => import('@/views/Partner/tanghuay-online/huay-list.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
  {
    path: '/tanghuay-online/list-detail/:id',
    name: 'tanghuay-list-detail',
    component: () => import('@/views/Partner/tanghuay-online/huay-list-detail.vue'),
    meta: {
      layout: 'full',
      resource: 'Seamless',
      action: 'read',
    },
  },
]
